@import './Utils/additional-variables.scss';
.accent-bg{
    background-color: $base-accent;
}
.accent-text{
    color: $base-accent;
}

.p-lite{
    padding-top: 7px;
    padding-left: 1px;
}

#email, #companyname, #subject, #message, #submitMessage{
    width: 625px;
}


.download-hero-btn{
   padding-left: 120px !important;
   padding-right: 120px !important;
}

@media (max-width: 764px) {
    #companyname, #email, #message,  #subject, #submitMessage{
        width: 300px;
    }
    .download-hero-btn{
        padding-left: 30px !important;
        padding-right: 30px !important;
     }
  }

.text-yellow{
    color: $base-yellow;
}

.hand-cursor{
    cursor: pointer;
}

.download-page{
    height: 550px;
    padding-top: 100px !important;
}
.invalid-page{
    height: 550px;
    padding-top: 80px !important;
    border-bottom: 1px solid rgb(235, 235, 235);
}

.header-shadow{
    box-shadow: 2px 2px 2px rgb(246, 246, 246);
}

.scroll-to-top-btn{
    position: fixed;
    right: 20px;
    bottom: 20px;
    opacity: .6;
    font-size: 25px;
}

.bg-light-blue{
    background-color: rgba(2, 103, 176, 0.065);
}

.video-configure{
    width:650px;
    height:366px;
}

@media (max-width: 764px) {
    .video-configure{
        width:350px;
        height:198px;
    }
}

@media (max-width: 450px) {
    .video-configure{
        width:300px;
        height:170px;
        padding-left: 18px;
    }
}